import React from "react";
import cx from "classnames";

import { DataTable, DateAndTime, PositionHead, TableRow } from "pages/AnalyticsV2/helper/table";
import { formatOpenPosition } from "pages/AnalyticsV2/helper";
import { formatAmount, formatAmountInDollar, parseValue } from "lib/numbers";

import icView from "img/ic-view.svg";
import { renderRisk } from "lib/helper";
import { getTokenSymbolFromString } from "domain/tokens";
import { useThemeContext } from "contexts/ThemeProvider";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import Tooltip from "components/Tooltip/Tooltip";
import { useMedia } from "react-use";

const OpenPositions = ({ positions, assets, marketTokensInfo, isEmpty }) => {
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const isTablet = useMedia("(max-width: 1023px)");

  const formattedPositions = positions.map((position) => {
    return formatOpenPosition(position, assets, marketTokensInfo);
  });

  return (
    <DataTable $noIndex $autoHeight $noHeading={isEmpty} className={lightThemeClassName}>
      <div className="head">
        <div className="col-name">Position</div>
        <div className="col-name">Net/PnL</div>
        <div className="col-name">Pos. Size</div>
        <div className="col-name">Liq. Risk</div>
        <div className="col-name">Liq./Mark Price</div>
        <div className="col-name">Avg. Entry Price</div>
        <div className="col-name">Created</div>
      </div>
      <div className="body">
        {formattedPositions.map((position, i) => {
          return (
            <TableRow key={position.key || i} to={`/leaderboard/v2/position/${position.posId}`}>
              <div d-label="Position" className="col-body">
                <PositionHead position={position} />
              </div>
              <div d-label="Net/PnL" className="col-body">
                <div className="col-body-content">
                  <div>
                    {position?.netValue?.lt(0) ? "-" : ""}$
                    {position?.netValue ? formatAmount(position.netValue, 30, 2, true).replace("-", "") : "--"}
                  </div>
                  {position?.pnlWithoutFee && (
                    <div
                      className={cx("fz-12", {
                        positive: position?.pnlAfterFee?.gt(0),
                        negative: position?.pnlAfterFee.lt(0),
                        muted: position?.pnlAfterFee.eq(0),
                      })}
                    >
                      <Tooltip
                        handle={formatAmountInDollar(position?.pnlAfterFee, 30, 2, true)}
                        position={isTablet && "right-bottom"}
                        className="fit-content nowrap"
                        handleClassName={cx("plain", { negative: position.hasLowCollateral })}
                        renderContent={() => {
                          return (
                            <>
                              <StatsTooltipRow
                                label={`PnL without fees`}
                                value={formatAmountInDollar(position?.pnlWithoutFee, 30, 2, true)}
                                showDollar={false}
                              />
                              <StatsTooltipRow
                                label={`Accrued funding fee`}
                                value={formatAmountInDollar(position?.accruedFundingFee, 30, 2, true)}
                                showDollar={false}
                              />
                              <StatsTooltipRow
                                label={`Accrued borrow fee`}
                                value={formatAmountInDollar(position?.accruedBorrowFee, 30, 2, true)}
                                showDollar={false}
                              />
                            </>
                          );
                        }}
                      />
                      <span
                        style={{ marginLeft: "4px" }}
                        className={cx("fz-12", {
                          positive: position?.pnlAfterFee?.gt(0),
                          negative: position?.pnlAfterFee.lt(0),
                          muted: position?.pnlAfterFee.eq(0),
                        })}
                      >
                        ({position.prefix}
                        {formatAmount(position?.percentPnl, 30, 2, true).replace("-", "")}
                        %)
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div d-label="Pos. Size" className="col-body">
                <div className="col-body-content">
                  ${formatAmount(parseValue(position?.size, 30), 30, 2, true)}
                  <div className="muted fz-12">
                    {formatAmount(position.posQty, 30, 4, true)}{" "}
                    {getTokenSymbolFromString(assets?.[position.tokenId]?.symbol)}
                  </div>
                </div>
              </div>
              <div d-label="Liq. Risk" className="col-body">
                {renderRisk(position.LiqRisk)}
              </div>
              <div d-label="Liq./Mark Price" className="col-body">
                <div className="col-body-content">
                  <div>
                    $
                    {formatAmount(
                      parseValue(position.liquidationPrice, 30),
                      30,
                      position.currentMarket?.decimals || 2,
                      true
                    )}
                  </div>
                  <div className="muted fz-12">
                    ${formatAmount(position.markPrice, 30, position.currentMarket?.decimals || 2, true)}
                  </div>
                </div>
              </div>
              <div d-label="Avg. Entry Price" className="col-body">
                ${formatAmount(parseValue(position.averagePrice, 30), 30, position.currentMarket?.decimals || 2, true)}
              </div>
              <div d-label="Created" className="col-body">
                <div className="col-body-content">
                  <DateAndTime unix={position.createdAt} />
                </div>
              </div>
              <img className="icon-view" src={icView} alt="view_ic" />
            </TableRow>
          );
        })}
      </div>
    </DataTable>
  );
};

export default OpenPositions;
