import { Select } from "antd";
import Tooltip from "components/Tooltip/Tooltip";
import useLeaderboard, { DEFAULT_SORT, configContest } from "hooks/useLeaderboard";
import downIc from "img/downArrow.svg";
import Pagination from "pages/Analytics/components/Pagination";
import styled from "styled-components";
import "../styles/AnalyticsV2.styled";

import Avatar from "components/Avatar";
import { useThemeContext } from "contexts/ThemeProvider";
import { BigNumber } from "ethers";
import { SECONDS_PER_DAY, shortenAddress } from "lib/legacy";
import { useMediaQuery } from "react-responsive";

import icView from "img/ic-view.svg";
import top1 from "img/top1.svg";
import top2 from "img/top2.svg";
import top2Light from "img/top2Light.svg";
import top3 from "img/top3.svg";
import top3Light from "img/top3Light.svg";

import { formatAmount } from "lib/numbers";
import moment from "moment";
import NoItemFound from "pages/Analytics/components/NoItemFound";
import { useMemo, useState } from "react";
import { DataTable, SkeletonLoadingTable, TableRow, renderArrows } from "../helper/table";

const topsImg = [top1, top2, top3];
const topsImgLight = [top1, top2Light, top3Light];

const PNL_DESC = "profitLoss DESC";
const PNL_ASC = "profitLoss ASC";
const VOLUME_DESC = "volume DESC";
const VOLUME_ASC = "volume ASC";

const ORDER_BY_OPTIONS = [
  {
    value: PNL_DESC,
    label: "Highest to lowest in PnL",
  },
  {
    value: PNL_ASC,
    label: "Lowest to highest in PnL",
  },
  {
    value: VOLUME_DESC,
    label: "Highest to lowest in Trading Volume",
  },
  {
    value: VOLUME_ASC,
    label: "Lowest to highest in Trading Volume",
  },
];

const MAPPED_BY_KEY = {
  volume: "Trading Volume",
  profitLoss: "PnL",
};

const TopTraders = ({ chainId, isValidChain }) => {
  const isSmallDevice = useMediaQuery({ query: "(max-width: 1023px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [from, setFrom] = useState();
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const { sort, handleSort, isLoading, data, currentPage, setCurrentPage, rowsPerPage, setRowsPerPage } =
    useLeaderboard(chainId, {
      toNow: true,
      from: from || 1698278400,
    });

  const FILTER_BY_OPTIONS = useMemo(() => {
    return [
      {
        label: "All time",
        value: configContest?.[chainId]?.START_TIMESTAMP,
      },
      {
        label: "24 Hours",
        value: moment().unix() - SECONDS_PER_DAY,
      },
      {
        label: "7 Days",
        value: moment().unix() - SECONDS_PER_DAY * 7,
      },
      {
        label: "1 Month",
        value: moment().unix() - SECONDS_PER_DAY * 30,
      },
    ];
  }, []);

  function getRank(index) {
    if (sort.type === "ASC") {
      const total = data.count;
      return total - index - (currentPage - 1) * rowsPerPage;
    }
    return currentPage * rowsPerPage - rowsPerPage + index + 1;
  }
  const isEmpty = !isLoading && data?.rows?.length === 0;
  return (
    <Wrapper className={lightThemeClassName}>
      {isValidChain && (
        <>
          <div className="top-filter">
            <StyledSelect1
              showArrow
              suffixIcon={<img className="downIc" src={downIc} alt="" />}
              onChange={(value) => {
                setFrom(value);
              }}
              defaultValue={FILTER_BY_OPTIONS[0]}
              bordered={false}
              options={FILTER_BY_OPTIONS}
              popupClassName={lightThemeClassName}
              className={lightThemeClassName}
            />
            <div className="last-update">
              Last update: {moment(data.lastUpdateTime ? data.lastUpdateTime * 1000 : Date.now()).fromNow()}
            </div>
          </div>
          {isSmallDevice && (
            <div className="filter-block">
              Ordered by:{" "}
              <StyledSelect
                showArrow
                suffixIcon={<img className="downIc" src={downIc} alt="" />}
                onChange={(value) => {
                  const [field, type] = value.split(" ");
                  handleSort(field, type);
                }}
                defaultValue={ORDER_BY_OPTIONS.find((opt) => opt.value === DEFAULT_SORT)}
                bordered={false}
                options={ORDER_BY_OPTIONS}
                popupClassName={lightThemeClassName}
                className={lightThemeClassName}
              />
            </div>
          )}
          {isEmpty ? (
            <NoItemFound />
          ) : (
            <DataTable $rowHeightSkeletonMobile="146.38px" className={lightThemeClassName}>
              <div className="head">
                <div className="col-name">
                  <Tooltip
                    handle={`Top`}
                    position="center-top"
                    className="fit-content"
                    renderContent={() => {
                      return <div>Sort by {MAPPED_BY_KEY[sort.field]}</div>;
                    }}
                  />
                </div>
                <div
                  className="col-name"
                  style={{
                    minWidth: 540,
                  }}
                >
                  Wallet
                </div>
                <div className="col-name">Win/Loss</div>
                <div className="col-name" onClick={() => handleSort("volume")}>
                  Trading Volume
                  {renderArrows("volume", sort, isLightTheme)}
                </div>
                <div className="col-name" onClick={() => handleSort("profitLoss")}>
                  <Tooltip
                    handle={`PnL`}
                    position="center-top"
                    renderContent={() => {
                      return <span>Realized PnL after all paid fees of closed and open positions</span>;
                    }}
                  />
                  {renderArrows("profitLoss", sort, isLightTheme)}
                </div>
              </div>
              <div className="body">
                {!isLoading &&
                  data?.rows?.map((row, i) => {
                    const rank = getRank(i);
                    const isPnlPositive = BigNumber.from(row.profitLoss).gt(0);

                    return (
                      <TableRow key={i} to={`/leaderboard/v2/account/${row.account}`}>
                        <div d-label="Top" className={`col-body top-${rank}`}>
                          {!isLightTheme && (
                            <>{topsImg[rank - 1] ? <img src={topsImg[rank - 1]} alt={`top-${rank}`} /> : rank}</>
                          )}
                          {isLightTheme && (
                            <>
                              {topsImgLight[rank - 1] ? <img src={topsImgLight[rank - 1]} alt={`top-${rank}`} /> : rank}
                            </>
                          )}
                        </div>

                        <div
                          d-label="Wallet"
                          className="col-body user col-min-width"
                          style={{
                            minWidth: 540,
                          }}
                        >
                          <Avatar address={row.account} size="24px" chainId={chainId} NFTid={undefined} />
                          {isMobile ? shortenAddress(row.account, 13) : row.account}
                        </div>
                        <div d-label="Win/Loss" className="col-body">
                          {row.wins}/{row.losses}
                        </div>
                        <div d-label="Trading Volume" className="col-body">
                          ${formatAmount(row.volume, 30, 2, true)}
                        </div>
                        <div d-label="PnL" className={`col-body ${isPnlPositive ? "positive" : "negative"}`}>
                          {isPnlPositive ? "+" : "-"}${formatAmount(row.profitLoss, 30, 2, true).replace("-", "")}
                        </div>
                        <img className="icon-view" src={icView} alt="view_ic" />
                      </TableRow>
                    );
                  })}
              </div>
              {isLoading && <SkeletonLoadingTable noHeading />}
            </DataTable>
          )}

          {!isEmpty && (
            <Pagination
              className="pagination-analytics"
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setCurrentPage={setCurrentPage}
              row={data?.count}
            />
          )}
        </>
      )}
      {!isValidChain && <NoItemFound />}
    </Wrapper>
  );
};

const StyledSelect1 = styled(Select)`
  &:not(.ant-select-customize-input) .ant-select-selector {
    width: fit-content;
    display: flex;
    height: 40px !important;
    padding: 0px var(--Base-Unit-S-12, 12px) !important;
    justify-content: center;
    align-items: center;
    gap: var(--Base-Unit-XS-8, 8px);
    border-radius: var(--Base-Unit-XS-8, 8px);
    border: 1px solid var(--Border, rgba(255, 255, 255, 0.1)) !important;
    background-color: var(--Nature-1, #191b2e) !important;
  }
`;

const StyledSelect = styled(StyledSelect1)`
  &:not(.ant-select-customize-input) .ant-select-selector {
    height: 44px !important;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto 0;

  .top-filter {
    margin: 0 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-select {
      border-radius: 8px;
    }

    .last-update {
      color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
      font-size: 14px;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
    }
  }

  .pagination-analytics {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .ant-select {
      & > .ant-select-selector {
        border-radius: 4px;
      }
    }

    .btn.btn-primary {
      border-radius: var(--Border_radius, 4px);
      border: 1px solid var(--Border, rgba(255, 255, 255, 0.1));
      width: 24px;
      height: 24px;
    }

    .btn-label {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1023px) {
    .filter-block {
      margin-bottom: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    /* .top-filter {
      margin-top: 16px;
    } */

    .filter-block {
      color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: flex-start;

      .ant-select-selector {
        padding-left: 8px;
      }
    }
  }

  &.theme--light {
    .ant-select {
    }
    .top-filter {
      .ant-select {
        background: #fff;
      }

      .last-update {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
      }
    }
  }
`;

export default TopTraders;
