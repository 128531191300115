import { TraderItemWrapper } from "../styles/AnalyticsV3.styled";

import { formatAmount } from "lib/numbers";
import Avatar from "components/Avatar";

import icView from "img/ic-view.svg";
import { memo } from "react";
import { getTokenLowerCase, getTokenSymbol } from "config/tokens";
import { getTokenIcon } from "./PositionDetail";

const PositionItem = ({
  item: {
    account,
    size,
    realisedPnl,
    isLong,
    indexToken,
    averagePrice,
    marketPrice,
    id,
    leverage,
    roi,
    netPnl,
    netValue,
    collateral,
  },
  rank,
  chainId,
  NFTid,
  isThemeLight,
}) => {
  const pnl = formatAmount(netPnl, 30, 3, true);
  const netPnlValue = formatAmount(netValue || 0, 30, 3, true);
  const isPnlPlus = !pnl.includes("-");
  const isNetPnlPlus = !netPnlValue.includes("-");

  return (
    <TraderItemWrapper to={`/leaderboard/position/${id}`} className="position-trader-wrapper">
      <div className="profile">
        <div className={`rank ${isThemeLight ? "light" : ""} ${rank < 4 ? "top" : ""} top-${rank}`}>
          {rank > 3 && rank}
        </div>
        <div className="token-info">
          <img src={getTokenIcon(chainId, indexToken)} alt="" />
          <div className="token-detail" style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <div className="name">
              {getTokenSymbol(chainId, indexToken)}/USD{" "}
              <span
                style={{
                  color: isLong ? (isThemeLight ? "#02B27F" : "#03F5AE") : isThemeLight ? "#D62F44" : "#f6475d",
                }}
              >
                {isLong ? "LONG" : "SHORT"}
                <span
                  style={{
                    color: isThemeLight ? "#D69E03" : "#FFC700",
                    backgroundColor: isThemeLight ? "#FEF6D8" : "#FFC70033",
                    borderRadius: "4px",
                    padding: "1px 4px",
                    marginLeft: "4px",
                    lineHeight: "16.8px",
                  }}
                >
                  {Number(leverage).toFixed(2)}X
                </span>
              </span>
            </div>
            <div className="price" style={{ lineHeight: "16.8px" }}>
              <span className="title">Entry Price:</span>{" "}
              <span className="value">
                ${formatAmount(averagePrice, 30, getTokenLowerCase(chainId, indexToken)?.displayDecimals || 2, true)}
              </span>{" "}
              | <span className="title">Mark Price:</span>{" "}
              <span className="value">
                ${formatAmount(marketPrice, 30, getTokenLowerCase(chainId, indexToken)?.displayDecimals || 2, true)}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <div data-name="Wallet" className="wallet">
        <div className="avatar">
          <Avatar address={account} size="30px" chainId={chainId} NFTid={NFTid} />
        </div>
        <div className="address">{account.slice(0, 6) + "..." + account.slice(account.length - 4, account.length)}</div>
      </div> */}
      <div data-name="Entry Price" className="entryPrice">
        ${formatAmount(averagePrice, 30, 2, true)}
      </div>
      <div data-name="Mark Price" className="markPrice">
        ${formatAmount(marketPrice, 30, 2, true)}
      </div>
      <div data-name="Size" className="size">
        ${formatAmount(size, 30, 2, true)}
      </div>
      <div data-name="Net Value" className={`PnL `}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            color: isThemeLight ? "#0D1A16" : "#fff",
          }}
          className="value"
        >
          <div style={{ lineHeight: "19.6px" }}>${formatAmount(netValue, 30, 2, true).replace("-", "")}</div>
        </div>
        <img className="icon-view" src={icView} alt="icView" />
      </div>
      <div data-name="Collateral" className="collateral">
        ${formatAmount(collateral, 30, 2, true)}
      </div>
      <div data-name="PnL" className={`PnL ${isPnlPlus ? "positive" : "negative"}`}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            color:
              Number(pnl) === 0
                ? "#ffffff"
                : isPnlPlus
                ? isThemeLight
                  ? "#02B27F"
                  : "#03F5AE"
                : isThemeLight
                ? "#D62F44"
                : "#f6475d",
          }}
          className="value"
        >
          <div style={{ lineHeight: "19.6px" }}>
            {isPnlPlus ? "+" : "-"}${formatAmount(netPnl, 30, 2, true).replace("-", "")}
          </div>
          <div style={{ lineHeight: "16.8px", fontSize: "12px" }}>
            ({isPnlPlus ? "+" : "-"}
            {Number(roi)
              .toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              .replace("-", "")}
            %)
          </div>
        </div>
        <img className="icon-view" src={icView} alt="icView" />
      </div>
    </TraderItemWrapper>
  );
};

export default memo(PositionItem);
