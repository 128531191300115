import React from "react";
import cx from "classnames";

import { DataTable, DateAndTime, PositionHead, TableRow } from "pages/AnalyticsV2/helper/table";
import { formatAmount, formatAmountInDollar } from "lib/numbers";
import { formatClosedPosition } from "pages/AnalyticsV2/helper";

import icView from "img/ic-view.svg";
import { useThemeContext } from "contexts/ThemeProvider";

const ClosedPositions = ({ positions, chainId, assets, isEmpty }) => {
  const { lightThemeClassName } = useThemeContext();

  const formattedPositions = positions.map((position) => {
    return formatClosedPosition(position, assets);
  });

  return (
    <DataTable $noIndex $autoHeight $noHeading={isEmpty} className={lightThemeClassName}>
      <div className="head">
        <div className="col-name">Position</div>
        <div className="col-name">Opened on</div>
        <div className="col-name">Closed on</div>
        <div className="col-name">Duration</div>
        <div className="col-name">Pos. Size</div>
        <div className="col-name">PnL/ROI</div>
        <div className="col-name">Avg. Entry Price</div>
        <div className="col-name">Exit Price</div>
      </div>
      <div className="body">
        {formattedPositions.map((position, i) => {
          return (
            <TableRow key={position.key || i} to={`/leaderboard/v2/position/${position.posId}`}>
              <div d-label="Position" className="col-body">
                <PositionHead position={position} />
              </div>
              <div d-label="Opened on" className="col-body">
                <div className="col-body-content">
                  <DateAndTime unix={position.createdAt} />
                </div>
              </div>
              <div d-label="Closed on" className="col-body">
                <div className="col-body-content">
                  <DateAndTime unix={position.closedAt} />
                </div>
              </div>
              <div d-label="Duration" className="col-body">
                {position.duration}
              </div>
              <div d-label="Pos. Size" className="col-body">
                <div className="col-body-content">
                  <div> ${formatAmount(position.totalSize, 30, 2, true)}</div>
                  <div className="muted fz-12">
                    {formatAmount(position.quantity, 30, 4, true)} {position.tokenSymbol}
                  </div>
                </div>
              </div>
              <div d-label="PnL/ROI" className="col-body">
                <div

                >
                  <div className={cx("col-body-content", {
                    positive: !position.realisedPnl.includes("-"),
                    negative: position.realisedPnl.includes("-"),
                  })}>
                    {formatAmountInDollar(position.realisedPnl, 30, 2, true, {
                      showPlus: true,
                    })}
                  </div>
                  <div className="muted fz-12">({position.roiText})</div>
                </div>
              </div>
              <div d-label="Avg. Entry Price" className="col-body">
                {formatAmountInDollar(position.averagePrice, 30, position.tokenDisplayDecimal, true)}
              </div>
              <div d-label="Exit Price" className="col-body">
                {formatAmountInDollar(position.markPrice, 30, position.tokenDisplayDecimal, true)}
              </div>
              <img className="icon-view" src={icView} alt="view_ic" />
            </TableRow>
          );
        })}
      </div>
    </DataTable>
  );
};

export default ClosedPositions;
