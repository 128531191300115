import { useMediaQuery } from "react-responsive";
import { formatAmount, formatNumber } from "lib/numbers";

import { TraderItemWrapper } from "../styles/Analytics.styled";
import Avatar from "components/Avatar";

import icView from "img/ic-view.svg";

const NUMBERS_OF_PRIZE = {
  ROI: {
    total: 10,
    prize: [1750, 1450, 1150, 90],
  },
  PROFIT: {
    total: 3,
    prize: [2400, 1600, 1000],
  },
};

const getPrize = (rank, type) => {
  return (
    formatNumber(
      NUMBERS_OF_PRIZE[type].prize[rank - 1] ?? NUMBERS_OF_PRIZE[type].prize[NUMBERS_OF_PRIZE[type].prize.length - 1],
      2
    ) + " USDC"
  );
  // return rank === 1 ? "7.5k ETH" : "4.5k ETH";
};

const TraderItem = ({ data, rank = 2, type, chainId, haveRibbon, NFTid, isThemeLight }) => {
  const { account, winTradeCount, settledTradeCount, realisedPnl, tradingVolume, collateral, roi, netPnl, totalPnl } =
    data;

  const pnl = formatAmount(totalPnl, 30, 3, true);

  const isPnlPlus = !pnl.includes("-");

  const tradingVolumeValue = formatAmount(tradingVolume, 30, 3, true);

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });

  const getClassNumber = () => (Number(pnl) === 0 ? "zero" : isPnlPlus ? "positive" : "negative");

  return (
    <TraderItemWrapper to={`/leaderboard/account/${account}`} className="trader-height">
      {type && haveRibbon && rank <= NUMBERS_OF_PRIZE[type].total && (
        <div className={`ribbon ${rank > 3 ? "ribbon-alpha" : ""}`}>{getPrize(rank, type)}</div>
      )}
      <div className="profile">
        <div className={`rank ${isThemeLight ? "light" : ""} ${rank < 4 ? "top" : ""} top-${rank}`}>
          {rank > 3 && rank}
        </div>
        <div className="detail">
          <div className="avatar">
            <Avatar address={account} chainId={chainId} NFTid={NFTid} />
          </div>
          <div className="address">
            {isSmallScreen ? account.slice(0, 6) + "..." + account.slice(account.length - 4, account.length) : account}
          </div>
        </div>
      </div>
      <div className="winLoss">
        {isSmallScreen ? (
          <div
            className="wrapper"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="label">Win/Loss</div>
            <div className="value">
              {winTradeCount}/{Number(settledTradeCount) - Number(winTradeCount)}
            </div>
          </div>
        ) : (
          <div>
            {winTradeCount}/{Number(settledTradeCount) - Number(winTradeCount)}
          </div>
        )}
      </div>
      <div className="tradingVol">
        {isSmallScreen ? (
          <div
            className="wrapper"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="label">Trading Volume</div>
            <div className="value">{tradingVolumeValue}</div>
          </div>
        ) : (
          <div>${tradingVolumeValue}</div>
        )}
      </div>
      <div className="PnL">
        {type === "ROI" ? (
          isSmallScreen ? (
            <div
              className="wrapper"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="label">ROI</div>
              <div className={`${getClassNumber()}`}>
                {isPnlPlus && "+"}
                {Number(roi).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                %
              </div>
            </div>
          ) : (
            <div className={`${getClassNumber()}`}>
              {isPnlPlus && "+"}
              {Number(roi).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              %
            </div>
          )
        ) : isSmallScreen ? (
          <div
            className="wrapper"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="label">PnL</div>
            <div className={`value ${getClassNumber()}`}>
              {isPnlPlus ? "+" : "-"}${pnl.includes("-") ? pnl.slice(1) : pnl}
            </div>
          </div>
        ) : (
          <div className={`${getClassNumber()}`}>
            {isPnlPlus ? "+" : "-"}${pnl.includes("-") ? pnl.slice(1) : pnl}
          </div>
        )}
      </div>

      <img className="icon-view" src={icView} alt="icView" />
    </TraderItemWrapper>
  );
};

export default TraderItem;
