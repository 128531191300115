// @ts-nocheck
// import ExternalLink from "components/ExternalLink/ExternalLink";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

import icView from "img/ic-view.svg";

import Modal from "components/Modal/Modal";
import { formatAmount, formatAmountInDollar, parseValue } from "lib/numbers";
import arrowRightIc from "img/ic_arrowright16.svg";

import Pagination from "../Pagination";
import NoItemFound from "../NoItemFound";
import Loader from "components/Common/Loader";
import { getApiAnalytics } from "pages/Analytics/config";
import { getTokenSymbol } from "config/tokens";
import Tooltip from "components/Tooltip/Tooltip";
import inInfo from "img/ic_info.svg";
import { useThemeContext } from "contexts/ThemeProvider";
import { DataTable, DateAndTime, PositionHead, SkeletonLoadingTable, TableRow } from "pages/Analytics/table";
import { BigNumber } from "ethers";
const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  > * {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const calcPnl = (realisedPnl) => {
  return formatAmount(realisedPnl, 30, 2, true);
};

const isPnlPlus = (realisedPnl) => {
  const pnl = calcPnl(realisedPnl);

  return !pnl.includes("-");
};

// const PositionModal = (props) => {
//   const { isVisible, setIsVisible, data } = props;
//   const { isLightTheme } = useThemeContext();
//   const renderTitle = () => {
//     return (
//       <>
//         <div style={{ color: isLightTheme ? "rgba(255, 172, 0, 1)" : "rgb(255,227,169)" }}>{data.title}</div>
//         <div style={{ color: isLightTheme ? "#02B27F" : "rgb(52, 245, 174)" }}>{data.longOrShort}</div>
//       </>
//     );
//   };
//   return (
//     <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={renderTitle()} isBottomMobal>
//       <StyledModal>
//         <div className="close-on child">
//           <div className="label">Closed on</div>

//           <div className="value">{data.closeOn}</div>
//         </div>

//         <div className="size child">
//           <div className="label">Size</div>

//           <div className="value">${data.size}</div>
//         </div>

//         <div className="collateral child">
//           <div className="label">Collateral</div>
//           <div className="value">${data.collateral}</div>
//         </div>

//         <div className="pnl child">
//           <div className="label">PnL</div>
//           <div className="value">
//             {" "}
//             <div
//               style={{
//                 color: isPnlPlus(data.pnl)
//                   ? isLightTheme
//                     ? "#02B27F"
//                     : "#03F5AE"
//                   : isLightTheme
//                   ? "#D62F44"
//                   : "#F6475D",
//               }}
//             >
//               {isPnlPlus(data.pnl) ? "+" : "-"}$
//               {calcPnl(data.pnl).includes("-") ? calcPnl(data.pnl).slice(1) : calcPnl(data.pnl)}
//             </div>
//           </div>
//         </div>

//         <div className="fee child">
//           <div className="label">Fee</div>
//           <div className="value">${data.fee}</div>
//         </div>

//         <div className="fee child">
//           <div className="label">Entry Price</div>
//           <div className="value">${data.entryPrice}</div>
//         </div>

//         <div className="fee child">
//           <div className="label">Exit Price</div>
//           <div className="value">${data.exitPrice}</div>
//         </div>

//         <div className="view-details child">{/* <Link to={`/leaderboard/position/${id}`}>View details</Link> */}</div>
//       </StyledModal>
//     </Modal>
//   );
// };

export default function ClosePositions({ account, chainId }) {
  const { lightThemeClassName } = useThemeContext() as any;
  const { isLightTheme } = useThemeContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [row, setRows] = useState(100);
  const [perPage, setPerPage] = useState(10);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const response = await axios(
        `${getApiAnalytics(
          chainId
        )}leaderboard/trader-list-closed?page=${currentPage}&pageSize=${perPage}&address=${account}`
      );

      setRows(response.data.count);
      setData(response.data.rows);
      setIsLoading(false);
    };

    fetchData();
  }, [account, currentPage, perPage, chainId]);

  // const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });

  // if (isSmallScreen)
  //   return (
  //     <>
  //       {!isLoading ? (
  //         data.map(
  //           (
  //             { id, settledTimestamp, isLong, size, collateral, realisedPnl, fee, averagePrice, exitPrice, indexToken },
  //             index
  //           ) => (
  //             <React.Fragment key={id}>
  //               <div className="table-mobile">
  //                 <div className="header">
  //                   <div
  //                     className="market"
  //                     style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
  //                     onClick={() => {
  //                       setIsVisible(true);
  //                       setModalData({
  //                         title: `${getTokenSymbol(chainId, indexToken)}/USD`,
  //                         longOrShort: `${isLong ? "LONG" : "SHORT"}`,
  //                         closeOn: `${moment(settledTimestamp * 1000).format("YYYY-MM-DD HH:mm:ss")}`,
  //                         size: `${formatAmount(size, 30, 3, true)}`,
  //                         collateral: `${formatAmount(collateral, 30, 3, true)}`,
  //                         pnl: realisedPnl,
  //                         fee: `${formatAmount(fee, 30, 3, true)}`,
  //                         entryPrice: `${formatAmount(averagePrice, 30, 3, true)}`,
  //                         exitPrice: `${formatAmount(exitPrice, 30, 3, true)}`,
  //                       });
  //                     }}
  //                   >
  //                     <div className="wrapper">
  //                       <div className="coin">{getTokenSymbol(chainId, indexToken)}/USD</div>
  //                       <div
  //                         style={{
  //                           color: isLong
  //                             ? isLightTheme
  //                               ? "#02B27F"
  //                               : "#03F5AE"
  //                             : isLightTheme
  //                             ? "#D62F44"
  //                             : "#f6475d",
  //                         }}
  //                         className="position"
  //                       >
  //                         {isLong ? "LONG" : "SHORT"}
  //                       </div>
  //                     </div>
  //                     <div className="icon">
  //                       <img src={arrowRightIc} alt="" />
  //                     </div>
  //                   </div>
  //                 </div>

  //                 <div className="content open-position">
  //                   <div className="close-on child">
  //                     <div className="label">Closed on</div>

  //                     <div className="value">{moment(settledTimestamp * 1000).format("YYYY-MM-DD HH:mm:ss")}</div>
  //                   </div>

  //                   <div className="size child">
  //                     <div className="label">Size</div>

  //                     <div className="value">${formatAmount(size, 30, 3, true)}</div>
  //                   </div>

  //                   <div className="pnl child">
  //                     <div className="label">
  //                       <Tooltip
  //                         handle={`PnL`}
  //                         position="left-bottom"
  //                         renderContent={() => {
  //                           return <p className="text-white">PnL after fees</p>;
  //                         }}
  //                       />
  //                     </div>
  //                     <div className="value">
  //                       {" "}
  //                       <div
  //                         style={{
  //                           color:
  //                             Number(calcPnl(realisedPnl)) === 0
  //                               ? "#ffffff"
  //                               : isPnlPlus(realisedPnl)
  //                               ? isLightTheme
  //                                 ? "#02B27F"
  //                                 : "#03F5AE"
  //                               : isLightTheme
  //                               ? "#D62F44"
  //                               : "#F6475D",
  //                         }}
  //                       >
  //                         {isPnlPlus(realisedPnl) ? "+" : "-"}$
  //                         {calcPnl(realisedPnl).includes("-") ? calcPnl(realisedPnl).slice(1) : calcPnl(realisedPnl)}
  //                       </div>
  //                     </div>
  //                   </div>

  //                   <div className="fee child">
  //                     <div className="label">Fee</div>
  //                     <div className="value">${formatAmount(fee, 30, 3, true)}</div>
  //                   </div>
  //                   <div className="view-details child">
  //                     <Link to={`/leaderboard/position/${id}`}>View details</Link>
  //                   </div>
  //                 </div>
  //               </div>
  //             </React.Fragment>
  //           )
  //         )
  //       ) : (
  //         // <Loader />
  //         <div className="skeleton-box" style={{ height: "30px", marginTop: "20px" }} />
  //       )}
  //       {data.length > 0 ? (
  //         <Pagination
  //           rowsPerPage={perPage}
  //           setRowsPerPage={setPerPage}
  //           row={row}
  //           currentPage={currentPage}
  //           setCurrentPage={setCurrentPage}
  //         />
  //       ) : (
  //         <NoItemFound />
  //       )}
  //       <PositionModal
  //         isVisible={isVisible}
  //         setIsVisible={setIsVisible}
  //         data={modalData}
  //         title={
  //           <>
  //             <div style={{ color: isLightTheme ? "rgba(255, 172, 0, 1)" : "rgb(255,227,169)", fontWeight: 500 }}>
  //               CAKE/USD
  //             </div>

  //             <div style={{ color: isLightTheme ? "#02B27F" : "#03F5AE", fontWeight: 600, fontSize: "12px" }}>
  //               SHORT
  //             </div>
  //           </>
  //         }
  //       />
  //     </>
  //   );

  return (
    <>
      <DataTable $noIndex $autoHeight className={lightThemeClassName}>
        <div className="head">
          <div className="col-name">Market</div>
          <div className="col-name">Closed on</div>
          <div className="col-name">Size</div>
          <div className="col-name">Collateral</div>
          <div className="col-name">
            <Tooltip
              handle={`PnL`}
              position="center-top"
              className="fit-content"
              renderContent={() => {
                return <div>PnL after fees</div>;
              }}
            />
          </div>
          <div className="col-name">Fee</div>
          <div className="col-name">Entry Price</div>
          <div className="col-name">Exit Price</div>
        </div>
        <div className="body">
          {data.map((position: any, i) => {
            const leverage = BigNumber.from(position.size)
              .mul(parseValue(1, 30))
              .div(BigNumber.from(position.collateral));
            return (
              <TableRow key={position.id || i} to={`/leaderboard/position/${position.id}` as any}>
                <div d-label="Market" className="col-body">
                  <PositionHead
                    position={{ ...position, leverage }}
                    defaultTokenSymbol={getTokenSymbol(chainId, position.indexToken)}
                  />
                </div>
                <div d-label="Closed on" className="col-body">
                  <div className="col-body-content">
                    <DateAndTime unix={position.settledTimestamp} />
                  </div>
                </div>
                <div d-label="Size" className="col-body">
                  ${formatAmount(position.size, 30, 2, true)}
                </div>
                <div d-label="Collateral" className="col-body">
                  ${formatAmount(position.collateral, 30, 2, true)}
                </div>
                <div
                  d-label="PnL"
                  className="col-body alternal-title"
                  style={{
                    color:
                      Number(calcPnl(position.pnlAfterFee)) === 0
                        ? "#ffffff"
                        : isPnlPlus(position.pnlAfterFee)
                        ? isLightTheme
                          ? "#02B27F"
                          : "#03F5AE"
                        : isLightTheme
                        ? "#D62F44"
                        : "#F6475D",
                  }}
                >
                  <Tooltip
                    handle={`PnL`}
                    position="left-top"
                    className="fit-content alternal-title-content nowrap"
                    renderContent={() => {
                      return <div>PnL after fees</div>;
                    }}
                  />
                  {isPnlPlus(position.pnlAfterFee) ? "+" : "-"}$
                  {calcPnl(position.pnlAfterFee).includes("-")
                    ? calcPnl(position.pnlAfterFee).slice(1)
                    : calcPnl(position.pnlAfterFee)}
                </div>
                <div d-label="Fee" className="col-body">
                  ${formatAmount(position.fee, 30, 2, true)}
                </div>
                <div d-label="Entry Price" className="col-body">
                  {formatAmountInDollar(position.averagePrice, 30, 2, true, {
                    autoDisplayDecimals: true,
                  })}
                </div>
                <div d-label="Exit Price" className="col-body">
                  {formatAmountInDollar(position.exitPrice, 30, 2, true, {
                    autoDisplayDecimals: true,
                  })}
                </div>
                <img className="icon-view" src={icView} alt="view_ic" />
              </TableRow>
            );
          })}
        </div>
        {isLoading && <SkeletonLoadingTable noHeading />}
      </DataTable>
      {data.length > 0 && (
        <Pagination
          rowsPerPage={perPage}
          setRowsPerPage={setPerPage}
          row={row}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
      {data.length === 0 && !isLoading && <NoItemFound />}
    </>
  );
}
