import Avatar from "components/Avatar";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { isEmpty } from "lodash";

import { TabWrapper } from "../styles/AnalyticsV3.styled";
import { Table, TDCell, THead, TRow } from "./Account.styled";
import BackUrl from "./BackUrl";
import MobilePositionHistoryItem from "./MobilePositionHistoryItem";
import axios from "axios";
import share from "img/share.svg";
import shareLight from "img/shareGray.svg";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { useMediaQuery } from "react-responsive";
import { Link, useParams } from "react-router-dom";
import { formatAmount, formatAmountInDollar } from "lib/numbers";
import moment from "moment";
import { getNormalizedTokenSymbol, getTokenLowerCase, getTokenSymbol } from "config/tokens";
import { importImage, shortenAddress } from "lib/legacy";
import { getApiAnalytics } from "../config";
import { getExplorerUrl } from "config/chains";
import useYourNFTId from "hooks/useYourNFTId";
import { useThemeContext } from "contexts/ThemeProvider";
import Pagination from "./Pagination";
import LinkSquareIcon from "components/Icon/LinkSquareIcon";

export const getTokenIcon = (chainId, tokenAddress) => {
  if (!chainId || !tokenAddress) return;
  const token = getTokenLowerCase(chainId, tokenAddress);
  return importImage(`ic_${getNormalizedTokenSymbol(token?.symbol)?.toLowerCase()}_40.svg`);
};

const StyledMobileHistoryContainer = styled.div`
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  &:hover {
    /* img {
      filter: invert(82%) sepia(64%) saturate(513%) hue-rotate(87deg) brightness(98%) contrast(97%);
    } */
    path {
      fill: #03f5ae;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.6);
  padding: 20px 0;
  .wallet-top {
    color: #adabab;
  }
  .info {
    display: flex;
    gap: 12px;

    img {
      width: 48px;
      height: 48px;
    }

    .name {
      font-size: 20px;
      margin-bottom: 4px;
      color: #fff;

      span {
        color: #34f5ae;
        font-size: 13px;
      }
    }

    .price {
      font-size: 12px;
      color: rgb(173, 171, 171);
      font-size: 14px;

      .value {
        color: white;
      }
    }

    .detail {
      display: flex;
      flex-direction: column;
    }
  }

  .wallet {
    align-self: flex-end;
    display: flex;
    align-items: center;

    @media (max-width: 1023px) {
      display: none;
    }

    img {
      width: 20px;
      height: 20px;
      margin: 0 4px;
    }

    a {
      color: #fff;
      font-weight: bold;
      text-decoration: none;
    }
  }

  @media screen and (max-width: 767px) {
    .info {
      align-items: center;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
`;

const Content = styled.div`
  padding: 20px 0;

  .detail {
    display: flex;
    gap: 48px;

    > *:first-child {
      display: none;
    }

    > .title {
      color: rgb(173, 171, 171);

      .value {
        color: white;
        font-weight: 700;
      }

      &.wallet {
        a {
          text-decoration: none;
          font-weight: bold;
        }
      }

      &.pnl {
        .value {
          color: #fa3c58;
          &.positive {
            color: #34f5ae;
          }
        }
      }

      &.liquidation {
        .value {
          color: rgb(255, 179, 19);
        }
      }
    }

    @media (max-width: 1023px) {
      flex-direction: column;
      gap: 8px;

      > *:first-child {
        display: block;
      }

      > .title {
        color: rgb(173, 171, 171);
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
          margin: 0 10px;
        }

        .value {
          display: flex;
          align-items: center;
        }

        &.pnl {
          span {
            color: #34f5ae;
          }
        }

        &.liquidation {
          span {
            color: rgb(255, 179, 19);
          }
        }

        span {
          color: white;
        }
      }
    }
  }
`;

const StyledTable = styled(Table)`
  width: 100%;
  border-collapse: collapse;
  border-radius: 0.4rem;
  border: 1px solid hsla(0, 0%, 100%, 0.062745098);

  @media (max-width: 1023px) {
    display: none;
  }
`;

const StyledTDCell = styled(TDCell)`
  padding: 14px 10px;
  color: white;
  background: #181b2e;
`;

const PositionDetail = ({ chainId, isThemeLight }) => {
  const { lightThemeClassName } = useThemeContext();
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1023px)" });
  const { position } = useParams();
  const [data, setData] = useState({});
  const NFTid = useYourNFTId(data?.account);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios(
        `${getApiAnalytics(chainId)}leaderboard/trade/${position}?page=${currentPage}&pageSize=${perPage}`
      );
      if (response?.data) {
        setData(response.data);
      }
    };
    fetchData();
    const timerId = setInterval(() => {
      fetchData();
    }, 20000);

    return () => {
      clearInterval(timerId);
    };
  }, [chainId, currentPage, perPage, position]);

  const calcPnl = (realisedPnl) => {
    return formatAmount(realisedPnl, 30, 3, true);
  };

  const isPnlPlus = (realisedPnl) => {
    const pnl = calcPnl(realisedPnl);

    return !pnl.includes("-");
  };
  const isClosed =
    data?.activities?.rows?.[0]?.type === "ClosePosition" || data?.activities?.rows?.[0]?.type === "LiquidatePosition";
  const renderType = (item) => {
    switch (item.type) {
      case "PartialClosePosition":
        return "Decrease";
      case "WithdrawCollateralPosition":
      case "DepositCollateralPosition":
        return "Edit Collateral";
      case "OpenPosition":
        return "New";
      case "ClosePosition":
        return "Close";
      case "LiquidatePosition":
        return "Liquidated";
      case "IncreasePosition":
        return "Increase";
      case "DecreasePosition":
        return "Decrease";
      default:
        return "";
    }
  };
  return (
    <TabWrapper className={`account-postion-details ${lightThemeClassName}`}>
      {!isEmpty(data) && (
        <>
          <div
            className="headingg"
            style={{
              display: "flex",
              alignItems: "center",
              gap: 16,
              borderBottom: "1px dashed rgba(255,255,255,0.6)",
            }}
          >
            <BackUrl defaultUrl={`/leaderboard/account/${data?.account}`} isV2 />
            <Header
              className="position-detail-head"
              style={{
                borderBottom: "none",
                flex: 1,
              }}
            >
              <div className="info">
                <img src={getTokenIcon(chainId, data.indexToken)} alt="" />
                <div className="detail">
                  <div className="name">
                    {getTokenSymbol(chainId, data.indexToken)}/USD{" "}
                    <span
                      style={{
                        color: data?.isLong
                          ? isThemeLight
                            ? "#02B27F"
                            : "#03F5AE"
                          : isThemeLight
                          ? "#D62F44"
                          : "#f6475d",
                      }}
                    >
                      {data?.isLong ? "LONG" : "SHORT"}
                    </span>
                    {data.leverage && (
                      <span
                        style={{
                          color: isThemeLight ? "#D69E03" : "#FFC700",
                          backgroundColor: isThemeLight ? "#FEF6D8" : "#FFC70033",
                          marginLeft: "4px",
                          padding: "1px 4px",
                          borderRadius: "4px",
                          fontWeight: 600,
                        }}
                      >
                        {Number(data.leverage).toFixed(2)}X
                      </span>
                    )}
                  </div>
                  <div className="price">
                    Entry Price:{" "}
                    <span className="value">
                      {formatAmountInDollar(data?.entryPrice, 30, 3, true, {
                        autoDisplayDecimals: true,
                      })}
                    </span>{" "}
                    | {isClosed ? "Exit Price" : "Mark Price"}:{" "}
                    <span className="value">
                      {isClosed
                        ? formatAmountInDollar(data?.activities?.rows?.[0]?.price, 30, 3, true, {
                            autoDisplayDecimals: true,
                          })
                        : formatAmountInDollar(data?.marketPrice, 30, 3, true, {
                            autoDisplayDecimals: true,
                          })}
                    </span>
                  </div>
                </div>
              </div>
              <div className="wallet wallet-top">
                Wallet:
                <Avatar address={data?.account} size="20px" chainId={chainId} NFTid={NFTid} />
                <Link to={`/leaderboard/account/${data?.account}`}>{data?.account}</Link>
              </div>
            </Header>
          </div>
          <Content className="position-detail-content">
            <div className="detail">
              <div className="title wallet">
                Wallet
                <span className="value">
                  <Avatar address={data?.account} size="20px" chainId={chainId} NFTid={NFTid} />
                  <Link to={`/leaderboard/account/${data?.account}`}>{shortenAddress(data?.account, 13)}</Link>
                </span>
              </div>
              <div className="title size">
                Size{isSmallScreen ? "" : ": "}
                <span className="value">${formatAmount(data?.size, 30, 2, true)}</span>
              </div>
              <div className="title collateral">
                Collateral Value{isSmallScreen ? "" : ": "}
                <span className="value">${formatAmount(data?.collateral, 30, 2, true)}</span>
              </div>
              <div className="title pnl">
                PnL{isSmallScreen ? "" : ": "}
                <span
                  className="value"
                  style={{
                    color:
                      Number(calcPnl(isClosed ? data?.pnlAfterFee : data?.netPnl)) === 0
                        ? "#ffffff"
                        : isPnlPlus(isClosed ? data?.pnlAfterFee : data?.netPnl)
                        ? isThemeLight
                          ? "#02B27F"
                          : "#03F5AE"
                        : isThemeLight
                        ? "#D62F44"
                        : "#f6475d",
                  }}
                >
                  {Number(calcPnl(isClosed ? data?.pnlAfterFee : data?.netPnl)) === 0
                    ? ""
                    : isPnlPlus(isClosed ? data?.pnlAfterFee : data?.netPnl)
                    ? "+"
                    : "-"}
                  $
                  {(isClosed ? data?.pnlAfterFee : data?.netPnl)
                    ? formatAmount(isClosed ? data?.pnlAfterFee : data?.netPnl, 30, 2, true).replace("-", "")
                    : "--"}
                </span>
              </div>
              <div className="title fee">
                Paid Fees{isSmallScreen ? "" : ": "}
                <span className="value">${formatAmount(data?.fee, 30, 2, true)}</span>
              </div>
              {!isClosed && (
                <div className="title liquidation">
                  Liquidation Price{isSmallScreen ? "" : ": "}
                  <span className="value">
                    {formatAmountInDollar(data?.liquidPrice, 30, 3, true, {
                      autoDisplayDecimals: true,
                    })}
                  </span>
                </div>
              )}
            </div>
          </Content>
          <StyledTable className="details-position-container">
            <TRow className="table-head">
              <THead style={{ fontWeight: 500 }}>Timestamp</THead>
              <THead style={{ fontWeight: 500 }}>Action</THead>
              <THead style={{ fontWeight: 500 }}>Collateral Delta</THead>
              <THead style={{ fontWeight: 500 }}>Size Delta</THead>
              <THead style={{ fontWeight: 500 }}>Paid Fee</THead>
              <THead style={{ fontWeight: 500 }}>Executed Price</THead>
            </TRow>
            {data &&
              data?.activities?.rows &&
              data?.activities?.rows.map((item, index) => {
                return (
                  <TRow key={index} className="row-item-body">
                    <StyledTDCell style={{ color: isThemeLight ? "#0D1A16" : "#fff" }}>
                      {moment(item?.timestamp * 1000).format("YYYY-MM-DD HH:mm:ss")}
                    </StyledTDCell>
                    <StyledTDCell>{renderType(item)}</StyledTDCell>
                    <StyledTDCell>
                      {item?.collateralValue != 0
                        ? item?.type === "OpenPosition" ||
                          item?.type === "DepositCollateralPosition" ||
                          item?.type === "IncreasePosition"
                          ? "+"
                          : "-"
                        : ""}

                      {item?.collateralValue != 0 ? "$" + formatAmount(item?.collateralValue, 30, 2, true) : ""}
                    </StyledTDCell>
                    <StyledTDCell>
                      {item?.sizeDelta != 0 &&
                      item?.type !== "DepositCollateralPosition" &&
                      item?.type !== "WithdrawCollateralPosition"
                        ? item?.type === "OpenPosition" || item?.type === "IncreasePosition"
                          ? "+"
                          : "-"
                        : ""}
                      {item?.sizeDelta != 0 &&
                      item?.type !== "DepositCollateralPosition" &&
                      item?.type !== "WithdrawCollateralPosition"
                        ? item?.sizeDelta
                          ? "$" + formatAmount(item?.sizeDelta, 30, 2, true).replace("-", "")
                          : "--"
                        : ""}
                    </StyledTDCell>
                    <StyledTDCell>{item?.fee != 0 ? "$" + formatAmount(item?.fee, 30, 2, true) : ""}</StyledTDCell>
                    <StyledTDCell
                      style={{ display: "flex", "justify-content": "space-between", "align-items": "center" }}
                    >
                      {formatAmountInDollar(item?.price, 30, 2, true, {
                        autoDisplayDecimals: true,
                      })}
                      <StyledExternalLink href={`${getExplorerUrl(chainId)}tx/${item.txhash}`} className="link-share">
                        {/* <img src={isThemeLight ? shareLight : share} alt="share" width={16} height={16} /> */}
                        <LinkSquareIcon />
                      </StyledExternalLink>
                    </StyledTDCell>
                  </TRow>
                );
              })}
          </StyledTable>
          <StyledMobileHistoryContainer>
            {data?.activities?.rows &&
              data?.activities?.rows.map((item, index) => (
                <MobilePositionHistoryItem className="custom-history" isNewStyle position={item} key={index} />
              ))}
          </StyledMobileHistoryContainer>
        </>
      )}
      <Pagination
        rowsPerPage={perPage}
        setRowsPerPage={setPerPage}
        row={data?.activities?.count}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </TabWrapper>
  );
};

export default PositionDetail;
