// @ts-nocheck
import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import inInfo from "img/ic_info.svg";
import { expandDecimals, formatAmount, formatAmountInDollar, formatNumber } from "lib/numbers";
import icView from "img/ic-view.svg";

import cx from "classnames";
import arrowRightIc from "img/ic_arrowright16.svg";

import Pagination from "../Pagination";
import NoItemFound from "../NoItemFound";
import Tooltip from "components/Tooltip/Tooltip";
import Loader from "components/Common/Loader";
import Modal from "components/Modal/Modal";
import { getApiAnalytics } from "pages/Analytics/config";
import { getTokenSymbol } from "config/tokens";
import { DataTable, PositionHead, SkeletonLoadingTable, TableRow } from "pages/Analytics/table";
import { useThemeContext } from "contexts/ThemeProvider";

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  > * {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const calcPnl = (realisedPnl) => {
  return formatAmount(realisedPnl, 30, 2, true);
};

const isPnlPlus = (realisedPnl) => {
  const pnl = calcPnl(realisedPnl);

  return !pnl.includes("-");
};

const calcPercenDelta = (delta, collateral) => {
  return (Math.abs(Number(delta) / Number(collateral)) * 100).toFixed(2);
};

// const PositionModal = (props) => {
//   const { isVisible, setIsVisible, data, isThemeLight } = props;
//   const renderTitle = () => {
//     return (
//       <>
//         <div style={{ color: isThemeLight ? "#0D1A16" : "rgb(255,227,169)" }}>{data.title}</div>
//         <div
//           style={{
//             color:
//               data.longOrShort === "LONG"
//                 ? isThemeLight
//                   ? "#02B27F"
//                   : "#03F5AE"
//                 : isThemeLight
//                 ? "#D62F44"
//                 : "#f6475d",
//           }}
//         >
//           {data.longOrShort}
//         </div>
//       </>
//     );
//   };
//   return (
//     <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={renderTitle()} isBottomMobal>
//       <StyledModal>
//         <div className="size child">
//           <div className="label">Size</div>

//           <div className="value">${data.size}</div>
//         </div>

//         <div className="net-value child">
//           <div className="label">Net Value</div>

//           <div className="wrapper value">
//             <div className="coin" style={{ textAlign: "right" }}>
//               ${data.netValue}
//             </div>
//             <div>
//               <div
//                 style={{
//                   color:
//                     Number(calcPnl(data.netPnl)) === 0
//                       ? isThemeLight
//                         ? "#000"
//                         : "#ffffff"
//                       : isPnlPlus(data.netPnl)
//                       ? isThemeLight
//                         ? "#02B27F"
//                         : "#03F5AE"
//                       : isThemeLight
//                       ? "#D62F44"
//                       : "#f6475d",
//                 }}
//                 className="position"
//               >
//                 {isPnlPlus(data.netPnl) ? "+" : "-"}$
//                 {calcPnl(data.netPnl).includes("-") ? calcPnl(data.netPnl).slice(1) : calcPnl(data.netPnl)} (
//                 {formatNumber(calcPercenDelta(data.netPnl, data.collateral))}%)
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="collateral child">
//           <div className="label">Mark Price</div>
//           <div className="value">${data.markPrice}</div>
//         </div>

//         <div className="entry child">
//           <div className="label">Entry Price</div>
//           <div className="value">${data.entryPrice}</div>
//         </div>

//         <div className="pnl child">
//           <div className="label">Liquidation Price</div>
//           <div
//             className="value"
//             style={{
//               color: isThemeLight ? "#D49007" : "#FFB313",
//             }}
//           >
//             ${data.liquidPrice}
//           </div>
//         </div>

//         <div className="liquidation Price child">
//           <div className="label">Collateral Asset</div>
//           <div className="value">{data.indexToken}</div>
//         </div>

//         <div className="view-details child"></div>
//       </StyledModal>
//     </Modal>
//   );
// };

export default function OpenPositions({ account, chainId, isThemeLight }) {
  const { lightThemeClassName } = useThemeContext() as any;
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [row, setRows] = useState(100);
  const [perPage, setPerPage] = useState(10);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios(
        `${getApiAnalytics(
          chainId
        )}leaderboard/trader-list-open?page=${currentPage}&pageSize=${perPage}&address=${account}`
      );

      setRows(response.data.count);
      setData(response.data.rows);
      setIsLoading(false);
    };
    const refreshData = async () => {
      const response = await axios(
        `${getApiAnalytics(
          chainId
        )}leaderboard/trader-list-open?page=${currentPage}&pageSize=${perPage}&address=${account}`
      );
      if (response?.data) {
        setRows(response.data.count);
        setData(response.data.rows);
        if (isLoading) setIsLoading(false);
      }
    };
    setIsLoading(true);
    fetchData();
    const timerId = setInterval(() => {
      refreshData();
    }, 20000);

    return () => {
      clearInterval(timerId);
    };
  }, [account, currentPage, perPage, chainId]);

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });

  // if (isSmallScreen)
  //   return (
  //     <>
  //       {!isLoading ? (
  //         data.map(
  //           (
  //             {
  //               id,
  //               isLong,
  //               size,
  //               collateral,
  //               collateralDelta,
  //               pnl,
  //               marketPrice,
  //               averagePrice,
  //               liquidPrice,
  //               indexToken,
  //               netPnl,
  //             },
  //             index
  //           ) => (
  //             <div className="table-mobile" key={index}>
  //               <div className="header">
  //                 <div
  //                   className="market"
  //                   style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
  //                   onClick={() => {
  //                     setIsVisible(true);
  //                     setModalData({
  //                       title: `${getTokenSymbol(chainId, indexToken)}/USD`,
  //                       longOrShort: `${isLong ? "LONG" : "SHORT"}`,
  //                       netValue: `${formatAmount(
  //                         expandDecimals(collateral, 0).add(expandDecimals(pnl, 0)),
  //                         30,
  //                         3,
  //                         true
  //                       )}`,
  //                       size: `${formatAmount(size, 30, 3, true)}`,
  //                       entryPrice: `${formatAmount(averagePrice, 30, 3, true)}`,
  //                       markPrice: `${formatAmount(marketPrice, 30, 3, true)}`,
  //                       liquidPrice: `${formatAmount(liquidPrice, 30, 3, true)}`,
  //                       indexToken: `${getTokenSymbol(chainId, indexToken)}`,
  //                       pnl: pnl,
  //                       collateral,
  //                     });
  //                   }}
  //                 >
  //                   <div className="wrapper">
  //                     <div className="coin">{getTokenSymbol(chainId, indexToken)}/USD</div>
  //                     <div
  //                       style={{
  //                         color: isLong ? (isThemeLight ? "#02B27F" : "#03F5AE") : isThemeLight ? "#D62F44" : "#f6475d",
  //                       }}
  //                       className="position"
  //                     >
  //                       {isLong ? "LONG" : "SHORT"}
  //                     </div>
  //                   </div>
  //                   <div className="icon">
  //                     <img src={arrowRightIc} alt="" />
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="content open-position">
  //                 <div className="size child">
  //                   <div className="label">Size</div>

  //                   <div className="value">${formatAmount(size, 30, 3, true)}</div>
  //                 </div>

  //                 <div className="net-value child">
  //                   <div className="label">Net Value</div>

  //                   <div className="wrapper value">
  //                     <div className="coin">
  //                       ${formatAmount(expandDecimals(collateral, 0).add(expandDecimals(netPnl, 0)), 30, 3, true)}
  //                     </div>
  //                     <div>
  //                       <div
  //                         style={{
  //                           color:
  //                             Number(calcPnl(netPnl)) === 0
  //                               ? isThemeLight
  //                                 ? "#000"
  //                                 : "#ffffff"
  //                               : isPnlPlus(netPnl)
  //                               ? isThemeLight
  //                                 ? "#02B27F"
  //                                 : "#03F5AE"
  //                               : isThemeLight
  //                               ? "#D62F44"
  //                               : "#f6475d",
  //                         }}
  //                         className="position"
  //                       >
  //                         {isPnlPlus(netPnl) ? "+" : "-"}$
  //                         {calcPnl(netPnl).includes("-") ? calcPnl(netPnl).slice(1) : calcPnl(netPnl)} (
  //                         {formatNumber(calcPercenDelta(netPnl, collateral))}%)
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>

  //                 <div className="liquidation Price child">
  //                   <div className="label">Collateral Asset</div>
  //                   <div className="value">{getTokenSymbol(chainId, indexToken)}</div>
  //                 </div>

  //                 <div className="view-details child">
  //                   <Link to={`/leaderboard/position/${id}`}>View details</Link>
  //                 </div>
  //               </div>
  //             </div>
  //           )
  //         )
  //       ) : (
  //         // <Loader />
  //         <div className="skeleton-box" style={{ height: "30px", marginTop: "20px" }} />
  //       )}

  //       {data.length > 0 ? (
  //         <Pagination
  //           rowsPerPage={perPage}
  //           setRowsPerPage={setPerPage}
  //           row={row}
  //           currentPage={currentPage}
  //           setCurrentPage={setCurrentPage}
  //         />
  //       ) : (
  //         <NoItemFound />
  //       )}
  //       <PositionModal
  //         isVisible={isVisible}
  //         setIsVisible={setIsVisible}
  //         data={modalData}
  //         isThemeLight={isThemeLight}
  //         title={
  //           <>
  //             <div style={{ color: isThemeLight ? "#0D1A16" : "#fff", fontWeight: 500 }}>CAKE/USD</div>
  //             <div style={{ color: isThemeLight ? "#02B27F" : "#03F5AE", fontWeight: 600, fontSize: "12px" }}>
  //               SHORT
  //             </div>
  //           </>
  //         }
  //       />
  //     </>
  //   );

  return (
    // <>
    //   {data.length > 0 ? (
    //     <>
    //       <div className="table">
    //         <div className="header open-position">
    //           <div className="market child">Position</div>
    //           <div className="size child">Size</div>
    //           <div className="net-value child">
    //             Net Value
    //           </div>
    //           <div className="collateral child">Mark Price</div>
    //           <div className="entry child">Entry Price</div>
    //           <div className="Liquidation Price child">Liquidation Price</div>
    //           <div className="child"> Collateral Asset</div>
    //           <div className="view-details child"></div>
    //         </div>

    //         {data.map(
    //           (
    //             {
    //               id,
    //               isLong,
    //               size,
    //               collateral,
    //               collateralDelta,
    //               pnl,
    //               netPnl,
    //               marketPrice,
    //               averagePrice,
    //               liquidPrice,
    //               indexToken,
    //               leverage,
    //             },
    //             index
    //           ) => (
    //             <div className="content open-position" key={index}>
    //               <div className="market child">
    //                 <div className="wrapper">
    //                   <div className="coin">{getTokenSymbol(chainId, indexToken)}/USD</div>
    //                   <div
    //                     style={{
    //                       color: isLong ? (isThemeLight ? "#02B27F" : "#03F5AE") : isThemeLight ? "#D62F44" : "#f6475d",
    //                       marginTop: "8px",
    //                     }}
    //                     className="position"
    //                   >
    //                     {isLong ? "LONG" : "SHORT"}
    //                     <span
    //                       style={{
    //                         color: isThemeLight ? "#D69E03" : "#FFC700",
    //                         backgroundColor: isThemeLight ? "#FEF6D8" : "#FFC70033",
    //                         marginLeft: "4px",
    //                         padding: "0 4px",
    //                         borderRadius: "4px",
    //                         fontWeight: 600,
    //                       }}
    //                     >
    //                       {Number(leverage).toFixed(2)}X
    //                     </span>
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="size child">${formatAmount(size, 30, 3, true)}</div>
    //               <div className="net-value child">
    //                 <div className="wrapper">
    //                   <div className="coin">
    //                     {expandDecimals(collateral, 0).add(expandDecimals(collateral, 0)).lt(0) ? "-" : ""}$
    //                     {formatAmount(
    //                       expandDecimals(collateral, 0).add(expandDecimals(netPnl, 0)),
    //                       30,
    //                       3,
    //                       true
    //                     ).replace("-", "")}
    //                   </div>
    //                   <div>
    //                     <div
    //                       style={{
    //                         color:
    //                           Number(calcPnl(netPnl)) === 0
    //                             ? isThemeLight
    //                               ? "#000 "
    //                               : "#ffffff"
    //                             : isPnlPlus(netPnl)
    //                             ? isThemeLight
    //                               ? "#02B27F"
    //                               : "#03F5AE"
    //                             : isThemeLight
    //                             ? "#D62F44"
    //                             : "#f6475d",
    //                       }}
    //                       className="position"
    //                     >
    //                       {isPnlPlus(netPnl) ? "+" : "-"}$
    //                       {calcPnl(netPnl).includes("-") ? calcPnl(netPnl).slice(1) : calcPnl(netPnl)} (
    //                       {isPnlPlus(netPnl) ? "+" : "-"}
    //                       {formatNumber(calcPercenDelta(netPnl, collateral))}%)
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="collateral child">${formatAmount(marketPrice, 30, 3, true)}</div>
    //               <div className="entry child">${formatAmount(averagePrice, 30, 3, true)}</div>
    //               <div
    //                 className="liquidation Price child"
    //                 style={{
    //                   color: isThemeLight ? "#D49007" : "#FFB313",
    //                 }}
    //               >
    //                 ${formatAmount(liquidPrice, 30, 3, true)}
    //               </div>
    //               <div className="child">{getTokenSymbol(chainId, indexToken)}</div>
    //               <div className="view-details child">
    //                 <Link to={`/leaderboard/position/${id}`}>View details</Link>
    //               </div>
    //             </div>
    //           )
    //         )}
    //       </div>

    //       <Pagination
    //         rowsPerPage={perPage}
    //         setRowsPerPage={setPerPage}
    //         row={row}
    //         currentPage={currentPage}
    //         setCurrentPage={setCurrentPage}
    //       />
    //     </>
    //   ) : (
    //     <NoItemFound />
    //   )}
    // </>
    <>
      {data.length > 0 && (
        <>
          <DataTable $noIndex $autoHeight className={lightThemeClassName}>
            <div className="head">
              <div className="col-name">Position</div>
              <div className="col-name">Size</div>
              <div className="col-name">Net Value</div>
              <div className="col-name">Mark Price</div>
              <div className="col-name">Entry Price</div>
              <div className="col-name">Liquidation Price</div>
              <div className="col-name">Collateral Asset</div>
            </div>
            <div className="body">
              {data.map((position: any, i) => {
                return (
                  <TableRow key={position.id || i} to={`/leaderboard/position/${position.id}` as any}>
                    <div d-label="Position" className="col-body">
                      <PositionHead
                        position={position}
                        defaultTokenSymbol={getTokenSymbol(chainId, position.indexToken)}
                      />
                    </div>
                    <div d-label="Size" className="col-body">
                      ${formatAmount(position.size, 30, 2, true)}
                    </div>
                    <div d-label="Net Value" className="col-body">
                      <div className="col-body-content">
                        <div>
                          {expandDecimals(position.collateral, 0).add(expandDecimals(position.collateral, 0)).lt(0)
                            ? "-"
                            : ""}
                          $
                          {formatAmount(
                            expandDecimals(position.collateral, 0).add(expandDecimals(position.netPnl, 0)),
                            30,
                            2,
                            true
                          ).replace("-", "")}
                        </div>
                        <div
                          className={cx("fz-12", {
                            positive: isPnlPlus(position.netPnl),
                            negative: !isPnlPlus(position.netPnl),
                          })}
                        >
                          {isPnlPlus(position.netPnl) ? "+" : "-"}$
                          {calcPnl(position.netPnl).includes("-")
                            ? calcPnl(position.netPnl).slice(1)
                            : calcPnl(position.netPnl)}{" "}
                          ({isPnlPlus(position.netPnl) ? "+" : "-"}
                          {formatNumber(calcPercenDelta(position.netPnl, position.collateral))}%)
                        </div>
                      </div>
                    </div>
                    <div d-label="Mark Price" className="col-body">
                      {formatAmountInDollar(position.marketPrice, 30, 2, true, {
                        autoDisplayDecimals: true,
                      })}
                    </div>
                    <div d-label="Entry Price" className="col-body">
                      {formatAmountInDollar(position.averagePrice, 30, 2, true, {
                        autoDisplayDecimals: true,
                      })}
                    </div>
                    <div d-label="Liquidation Price" className="col-body">
                      {formatAmountInDollar(position.liquidPrice, 30, 2, true, {
                        autoDisplayDecimals: true,
                      })}
                    </div>
                    <div
                      d-label="Collateral Asset
"
                      className="col-body"
                    >
                      {getTokenSymbol(chainId, position.collateralToken)}
                    </div>
                    <img className="icon-view" src={icView} alt="view_ic" />
                  </TableRow>
                );
              })}
            </div>
            {isLoading && <SkeletonLoadingTable noHeading />}
          </DataTable>
          <Pagination
            rowsPerPage={perPage}
            setRowsPerPage={setPerPage}
            row={row}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      )}
      {data.length === 0 && !isLoading && <NoItemFound />}
    </>
  );
}
