import React from "react";

import { DataTable, DateAndTime, PositionHead, TableRow } from "pages/AnalyticsV2/helper/table";
import { formatOrder } from "pages/AnalyticsV2/helper";
import { formatAmount, formatAmountInDollar, limitDecimals, parseValue } from "lib/numbers";

import icView from "img/ic-view.svg";
import { BigNumber } from "ethers";
import { useThemeContext } from "contexts/ThemeProvider";

const renderPosSize = (position) => {
  let result = { size: 0, quantity: 0 };
  if (position.type === "TRIGGER") {
    const size = parseValue(position.size, 30);
    const averagePrice = parseValue(position.averagePrice, 30);

    const posQty = size.gt(0) ? size.mul(parseValue(1, 30)).div(averagePrice) : 0;
    result = {
      size: formatAmount(size, 30, 2, true),
      quantity: formatAmount(posQty, 30, 4, true),
    };
  } else if (position.type === "PENDDING") {
    const posQty = BigNumber.from(position.pendingSize)
      .mul(parseValue(1, 30))
      .div(BigNumber.from(position.positionType === "Limit Order" ? position?.lmtPrice : position?.stpPrice));
    result = {
      size: formatAmount(position.pendingSize, 30, 2, true),
      quantity: formatAmount(posQty, 30, 4, true),
    };
  } else {
    const size = BigNumber.from(position.size);
    const averagePrice = BigNumber.from(position.averagePrice);
    const posQty = size.mul(parseValue(1, 30)).div(averagePrice);
    result = {
      size: formatAmount(size, 30, 2, true),
      quantity: formatAmount(posQty, 30, 4, true),
    };
  }
  return result;
};

const renderCollateral = (position) => {
  const collateral = parseValue(position.collateral, 30);
  if (position.type === "TRIGGER") {
    return formatAmountInDollar(collateral, 30, 2, true);
  } else if (position.type === "PENDDING") return formatAmountInDollar(position.pendingCollateral, 30, 2, true);
  else return formatAmountInDollar(BigNumber.from(position.collateral), 30, 2, true);
};

const renderParamaters = (position) => {
  let result = { param1: 0, param1Sub: "0", param2: null, param2Sub: "%" };

  if (position.type === "TRIGGER") {
    const conditionQuantity = Number(position.trigger.amountPercent / 1000);
    result = {
      ...result,
      param1: `${formatAmount(position?.trigger?.price, 30, 2, true)}`,
      param1Sub: position.trigger.isTP ? "TP" : "SL",
      param2: conditionQuantity?.toString().includes(".") ? limitDecimals(conditionQuantity, 2) : conditionQuantity,
    };
  } else if (position.type === "PENDDING") {
    result = {
      ...result,
      param1: formatAmount(
        position.positionType === "Limit Order" ? position?.lmtPrice : position?.stpPrice,
        30,
        2,
        true
      ),
      param1Sub: position.positionType === "Limit Order" ? "LP" : "SP",
      param2: position.positionType === "Stop Limit" ? formatAmount(position?.lmtPrice, 30, 2, true) : null,
      param2Sub: position.positionType === "Stop Limit" ? "LP" : null,
    };
  } else {
    const size = BigNumber.from(position.size);
    const sizeDelta = BigNumber.from(position.sizeDelta);
    const amountPercent = sizeDelta.mul(parseValue(1, 30)).div(size);
    result = {
      ...result,
      param1:
        position.stepType == 0
          ? formatAmount(BigNumber.from(position.stepAmount), 30, 2, true)
          : formatAmount(BigNumber.from(position.stepAmount), 3, 2, true),
      param1Sub: position.stepType == 1 ? "T%" : "TA",
      param2: size.eq(sizeDelta) ? 100 : formatAmount(amountPercent, 30, 2, true),
    };
  }
  return result;
};

const Orders = ({ orders, chainId, assets, marketTokensInfo, isEmpty }) => {
  const { lightThemeClassName } = useThemeContext();

  const formattedOrders = orders.map((order) => {
    return formatOrder(order, assets, marketTokensInfo);
  });

  return (
    <DataTable $noIndex $autoHeight $noHeading={isEmpty} className={lightThemeClassName}>
      <div className="head">
        <div className="col-name">Position</div>
        <div className="col-name">Created</div>
        <div className="col-name">Type</div>
        <div className="col-name">Pos. Size</div>
        <div className="col-name">Collateral</div>
        <div className="col-name">Parameters</div>
      </div>
      <div className="body">
        {formattedOrders.map((order, i) => {
          return (
            <TableRow key={order.key || i} to={`/leaderboard/v2/position/${order.posId || order.id}`}>
              <div d-label="Position" className="col-body">
                <PositionHead position={order} />
              </div>
              <div d-label="Created" className="col-body">
                <div className="col-body-content">
                  <DateAndTime unix={order.createdAt} />
                </div>
              </div>
              <div d-label="Type" className="col-body">
                {order.typeText}
              </div>
              <div d-label="Pos. Size" className="col-body">
                <div className="col-body-content">
                  <div>${renderPosSize(order).size}</div>
                  <div className="muted fz-12">
                    {renderPosSize(order).quantity} {order.tokenSymbol}
                  </div>
                </div>
              </div>
              <div d-label="Collateral" className="col-body">
                {renderCollateral(order)}
              </div>
              <div d-label="Parameters" className="col-body">
                <div className="col-body-content">
                  <div>
                    {renderParamaters(order).param1} <span className="muted">{renderParamaters(order).param1Sub}</span>
                  </div>
                  {renderParamaters(order).param2 && (
                    <div className="muted fz-12">
                      {renderParamaters(order).param2} <span>{renderParamaters(order).param2Sub}</span>
                    </div>
                  )}
                </div>
              </div>
              <img className="icon-view" src={icView} alt="view_ic" />
            </TableRow>
          );
        })}
        {/* {isLoading && <div className="skeleton-box" style={{ height: "30px", marginTop: "20px" }} />} */}
      </div>
    </DataTable>
  );
};

export default Orders;
