import Bepe from "img/aggregator/tokens/bepe.png";
import D1 from "img/aggregator/tokens/d1.png";
import Dyor from "img/aggregator/tokens/dyor.png";
import Eth from "img/aggregator/tokens/eth.png";
import Hype from "img/aggregator/tokens/hype.png";
import Mia from "img/aggregator/tokens/MIA.png";
import Mim from "img/aggregator/tokens/mim.png";
import Thruster from "img/aggregator/tokens/thruster.png";
import Usdb from "img/aggregator/tokens/usdb.png";
import Weth from "img/aggregator/tokens/weth.png";
import Yes from "img/aggregator/tokens/yes.png";
import Usdc from "img/aggregator/tokens/usdc.svg";
import Wbtc from "img/aggregator/tokens/wbtc.svg";
import Wftm from "img/aggregator/tokens/wftm.svg";
import Usdt from "img/aggregator/tokens/usdt.svg";
import Aave from "img/aggregator/tokens/AAVE.png";
import ankrFTM from "img/aggregator/tokens/ankrFTM.png";
import Any from "img/aggregator/tokens/ANY.png";
import Atri from "img/aggregator/tokens/atari.png";
import Brush from "img/aggregator/tokens/BRUSH.png";
import crvUSD from "img/aggregator/tokens/crvUSD.png";
import Dai from "img/aggregator/tokens/DAI.png";
import Fvm from "img/aggregator/tokens/FVM.png";
import Fxs from "img/aggregator/tokens/FXS.png";
import Lqdr from "img/aggregator/tokens/LQDR.png";
import Lshare from "img/aggregator/tokens/LSHARE.png";
import Mai from "img/aggregator/tokens/MAI.png";
import SfrxETH from "img/aggregator/tokens/sfrxETH.png";
import Sushi from "img/aggregator/tokens/SUSHI.svg";
import Volta from "img/aggregator/tokens/Volta.png";
import Wmemo from "img/aggregator/tokens/wMEMO.png";
import Pills from "img/aggregator/tokens/pills.png";
import Tshare from "img/aggregator/tokens/tshare.png";
import Yfi from "img/aggregator/tokens/yfi.png";
import Btc from "img/aggregator/tokens/btc.svg";
import Badger from "img/aggregator/tokens/badger.png";
import Band from "img/aggregator/tokens/band.png";
import Bifi from "img/aggregator/tokens/bifi.png";
import Bnb from "img/aggregator/tokens/bnb.png";
import Knc from "img/aggregator/tokens/KNC.png";
import Cream from "img/aggregator/tokens/cream.png";
import Crv from "img/aggregator/tokens/crv.png";
import Elk from "img/aggregator/tokens/elk.png";
import Fbomb from "img/aggregator/tokens/fbomb.png";
import Mmy from "img/aggregator/tokens/mmy.svg";
import Link from "img/aggregator/tokens/link.png";
import Mpx from "img/aggregator/tokens/mpx.png";
import Beets from "img/aggregator/tokens/BEETS.png";
import Bay from "img/aggregator/tokens/bay.png";
import HOB from "img/aggregator/tokens/HOB.png";
import Wigo from "img/aggregator/tokens/wigo.png";
import Spirit from "img/aggregator/tokens/SPIRIT.png";
import Gogo from "img/aggregator/tokens/gogo.png";
import MUTTSKI from "img/aggregator/tokens/Muttski.png";
import Speedy from "img/aggregator/tokens/speedy.png";
import DEUS from "img/aggregator/tokens/DEUS.png";
import sftmx from "img/aggregator/tokens/sftmx.png";
import SCREAM from "img/aggregator/tokens/scream.png";
import YOSHI from "img/aggregator/tokens/yoshi.png";
import TAROT from "img/aggregator/tokens/TAROT.png";
import IronICE from "img/aggregator/tokens/IronICE.png";
import FTMO from "img/aggregator/tokens/FTMO.png";
import TCS from "img/aggregator/tokens/TCS.png";
import RIP from "img/aggregator/tokens/RIP.png";
import TRAVA from "img/aggregator/tokens/TRAVA.png";
import Death from "img/aggregator/tokens/DEATH.png";
import Metti from "img/aggregator/tokens/Metti.png";
import FOO from "img/aggregator/tokens/FOO.png";
import SYF from "img/aggregator/tokens/SYF.png";
import SMART from "img/aggregator/tokens/SMART.png";
import FRAX from "img/aggregator/tokens/FRAX.png";
import MM from "img/aggregator/tokens/MM.png";
import WOOFY from "img/aggregator/tokens/WOOFY.png";
import GTON from "img/aggregator/tokens/GTON.png";
import COVER from "img/aggregator/tokens/COVER.png";
import SHADE from "img/aggregator/tokens/SHADE.png";
import xBOO from "img/aggregator/tokens/xBOO.png";
import BOO from "img/aggregator/tokens/BOO.png";
import FUSD from "img/aggregator/tokens/fUSD.png";
import KEK from "img/aggregator/tokens/KEK.png";
import TOMB from "img/aggregator/tokens/TOMB.png";
import BOMB from "img/aggregator/tokens/BOMB.png";
import DMD from "img/aggregator/tokens/DMD.png";
import DOLA from "img/aggregator/tokens/DOLA.png";
import CFi from "img/aggregator/tokens/CFi.png";
import SING from "img/aggregator/tokens/SING.png";
import FONT from "img/aggregator/tokens/fONT.png";
import SPELL from "img/aggregator/tokens/SPELL.png";
import MESO from "img/aggregator/tokens/MESO.png";
import CoUSD from "img/aggregator/tokens/CoUSD.png";
import HEC from "img/aggregator/tokens/HEC.png";
import FHM from "img/aggregator/tokens/FTM.png";
import INV from "img/aggregator/tokens/INV.png";
import MODA from "img/aggregator/tokens/MODA.png";
import SOUL from "img/aggregator/tokens/SOUL.png";
import TUSD from "img/aggregator/tokens/TUSD.png";
import FEED from "img/aggregator/tokens/FEED.png";
import EXOD from "img/aggregator/tokens/EXOD.png";
import FS from "img/aggregator/tokens/FS.png";
import JulD from "img/aggregator/tokens/JuID.png";
import GEIST from "img/aggregator/tokens/GEIST.png";
import fSWAMP from "img/aggregator/tokens/fSWAMP.png";
import WOO from "img/aggregator/tokens/WOO.png";
import FANG from "img/aggregator/tokens/FANG.png";
import TREEB from "img/aggregator/tokens/TREEB.png";
import STEAK from "img/aggregator/tokens/STEAK.png";
import YEL from "img/aggregator/tokens/YEL.png";
import COFFIN from "img/aggregator/tokens/COFFIN.png";
import ANGEL from "img/aggregator/tokens/ANGEL.png";
import frxETH from "img/aggregator/tokens/frxETH.png";
import TokenDefault from "img/aggregator/tokens/token-default.svg";
import { FANTOM } from "config/chains";
const TOKEN_ICONS = {
  ETH: Eth,
  WETH: Weth,
  USDB: Usdb,
  MIM: Mim,
  Thruster: Thruster,
  Dyor: Dyor,
  YES: Yes,
  BEPE: Bepe,
  D1: D1,
  MIA: Mia,
  USDC: Usdc,
  USDT: Usdt,
  WBTC: Wbtc,
  BTC: Btc,
  WFTM: Wftm,
  FTM: Wftm,
  AAVE: Aave,
  ankrFTM: ankrFTM,
  ANY: Any,
  ATRI: Atri,
  BRUSH: Brush,
  crvUSD: crvUSD,
  DAI: Dai,
  FVM: Fvm,
  FXS: Fxs,
  LQDR: Lqdr,
  LSHARE: Lshare,
  MAI: Mai,
  sfrxETH: SfrxETH,
  Volta: Volta,
  wMEMO: Wmemo,
  PILLS: Pills,
  TSHARE: Tshare,
  YFI: Yfi,
  axlBTC: Btc,
  axlETH: Eth,
  axlUSDC: Usdc,
  axlUSDT: Usdt,
  BADGER: Badger,
  BAND: Band,
  BIFI: Bifi,
  BNB: Bnb,
  beFTM: Wftm,
  axlWMAI: Mai,
  axlLqdr: Lqdr,
  KNC: Knc,
  axlKNC: Knc,
  CREAM: Cream,
  CRV: Crv,
  GEIST,
  ELK: Elk,
  FBOMB: Fbomb,
  MMY: Mmy,
  multiBTC: Btc,
  multiDAI: Dai,
  multiUSDC: Usdc,
  multiUSDT: Usdt,
  LINK: Link,
  MPX: Mpx,
  SUSHI: Sushi,
  BEETS: Beets,
  BAY: Bay,
  HOB: HOB,
  WIGO: Wigo,
  SPIRIT: Spirit,
  "USDC.e": Usdc,
  GOGO: Gogo,
  MUTTSKI: MUTTSKI,
  SPEEDY: Speedy,
  DEUS: DEUS,
  sFTMX: sftmx,
  SCREAM: SCREAM,
  YOSHI: YOSHI,
  TAROT,
  IronICE,
  frxETH: frxETH,
  FTMO,
  TCS,
  RIP,
  DOLA,
  TRAVA,
  Metti,
  Death,
  FOO,
  SYF,
  SMART,
  FRAX,
  MM,
  WOOFY,
  GTON,
  COVER,
  SHADE,
  xBOO,
  BOO,
  FUSD,
  KEK,
  TOMB,
  JulD,
  YEL,
  iFUSD: FUSD,
  BOMB,
  DMD,
  CFi,
  SING,
  FONT,
  SPELL,
  MESO,
  CoUSD,
  HEC,
  FHM,
  INV,
  STEAK,
  MODA,
  SOUL,
  TUSD,
  FEED,
  EXOD,
  FS,
  fSWAMP,
  WOO,
  TREEB,
  FANG,
  COFFIN,
  fBOMB: BOMB,
  ANGEL,
  default: TokenDefault,
};
export const getLocalTokenIcon = (symbol) => {
  return TOKEN_ICONS?.[symbol];
};
export const getLogoUrlByChainId = (chainId: number, address: string, hasIcon: boolean): string => {
  if (!address || !hasIcon) return "";
  if (chainId) {
    if (address === "ether") {
      switch (chainId) {
        case FANTOM:
          return require("img/ic_ftm_24.svg");
        default:
          return "";
      }
    }
    return "https://dd.dexscreener.com/ds-data/tokens/fantom/{checksum}.png".replace(
      "{checksum}",
      address.toLowerCase()
    );
  }
  return "";
};
